import serviceTokens from "services/tokens";
import serviceApi from "services/api";
import serviceUser from "services/user";
import store from "store";
import { setTokens, clearTokens } from "slicers/auth";
import { clearState } from "slicers/user";

const baseURL = "auth";

const loadTokens = () => {
  const access = serviceTokens.getAccess() || "";
  const refresh = serviceTokens.getRefresh() || "";
  store.dispatch(setTokens({ access, refresh }));
}

const saveTokens = ({
  access = "",
  refresh = ""
}) => {
  serviceTokens.saveAccess(access);
  serviceTokens.saveRefresh(refresh);
  store.dispatch(setTokens({ access, refresh }));
}

const removeTokens = () => {
  serviceTokens.removeAccess();
  serviceTokens.removeRefresh();
  store.dispatch(clearTokens());
};

const isAuthed = (refreshToken: string) => {
  if (!refreshToken) {
    return false;
  }
  return new Date() < getExpirationDate(refreshToken);
};

const getExpirationDate = (token: string) => new Date(getValueFromTokenPayload(token, "exp") * 1000);

const getValueFromTokenPayload = (token: string, key: string) => JSON.parse(atob(token.split(".")[1]))[key];

const getUserRole = (token: string) => getValueFromTokenPayload(token, "user_role");

const refresh = () =>
  serviceApi
    .post(baseURL + "/refresh", {
      refresh: store.getState().auth.refresh,
    })
    .then(saveTokens)
    .then(serviceUser.get);

const login = async (email: string, password: string) =>
  serviceApi
    .post(baseURL + "/login", { email: email.trim(), password })
    .then(saveTokens)
    .then(serviceUser.get);

const logout = async () =>
  serviceApi.post(baseURL + "/logout").then(() => {
    removeTokens();
    store.dispatch(clearState());
  });

const service = {
  loadTokens,
  getUserRole,
  refresh,
  removeTokens,
  isAuthed,
  login,
  logout
};

export default service;
