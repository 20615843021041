import { useSelector } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import { RootState } from "store";
import iconUsers from "assets/icons/users.svg";
import iconFirmwares from "assets/icons/firmware.svg";
import iconLogOut from "assets/icons/log-out.svg"
import s from "./index.module.scss";

const AdminHeader = () => {
  const firstName = useSelector((state: RootState) => state.user.firstName);

  return (
    <header className={s["admin-header"]}>
      <nav className={s["admin-header__navigation"]}>
        <NavLink
          to="/admin/users/admins"
          className={
            ({ isActive }) => isActive
              ? `${s["admin-header__navigation-link"]} ${s["admin-header__navigation-link--active"]}`
              : s["admin-header__navigation-link"]
          }
        >
          <img src={iconUsers} alt="Admins management page" />
        </NavLink>
        <NavLink
          to="/admin/firmwares"
          className={
            ({ isActive }) => isActive
              ? `${s["admin-header__navigation-link"]} ${s["admin-header__navigation-link--active"]}`
              : s["admin-header__navigation-link"]
          }
        >
          <img src={iconFirmwares} alt="Firmwares management page" />
        </NavLink>
      </nav>
  
      <h1 className={s["admin-header__title"]}>S-6000 Sleep Sound Therapy System®</h1>
  
      <section className={s["admin-header__greeting"]}>
        <p className={s["admin-header__greeting-text"]}>Hello, {firstName}!</p>
        <Link to="/logout">
          <img src={iconLogOut} alt="Log out" />
        </Link>
      </section>
    </header>
  );
}

export default AdminHeader;
