import utilTranslators from "utils/translators";

const compareVersions = (
  firstVersion: string,
  secondVersion: string
): number => {
  const firstVersionNumber = utilTranslators.versionStringToVersionNumber(firstVersion);
  const secondVersionNumber = utilTranslators.versionStringToVersionNumber(secondVersion);

  if (firstVersionNumber > secondVersionNumber) return -1;
  if (firstVersionNumber < secondVersionNumber) return 1;
  return 0;
};

const util = {
  compareVersions,
};

export default util;
