const formatDate = (date: string): string => {
  const d = new Date(date);

  const yyyy = d.getFullYear();
  const mm = d.getMonth() + 1;
  const dd = d.getDate();

  return [
    (mm > 9 ? '' : '0') + mm,
    (dd > 9 ? '' : '0') + dd,
    yyyy
  ].join('/');
};

const formatFirmwareState = (firmwareState: string): string => {
  if (firmwareState === "release") return "Released";
  if (firmwareState === "pending") return "Pending for release";
  return "Unknown";
};

const formatFirmwareFormFieldsStates = (titleState: string, versionState: string, patchNotesState: string) : 
  {formattedTitle: string, formattedVersion: string, formattedPatchNotes: string} => {
    const formattedTitle = titleState.trim();
    const formattedVersion = versionState;
    const formattedPatchNotes = patchNotesState.trim();
  
    return {
      formattedTitle,
      formattedVersion,
      formattedPatchNotes
    }
  }

const util = {
  formatDate,
  formatFirmwareState,
  formatFirmwareFormFieldsStates
};

export default util;
