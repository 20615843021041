import { Routes, Route, Navigate } from "react-router-dom";
import AdminHeader from "components/headers/AdminHeader";
import Admins from "pages/admin/users/admins";
import Firmwares from "pages/admin/firmwares";
import s from "./index.module.scss";

const Admin = () => (
  <div className={s["admin"]}>
    <AdminHeader />

    <section className={s["admin__page-wrapper"]}>
      <Routes>
        <Route path="/users/admins" element={<Admins />} />
        <Route path="/firmwares" element={<Firmwares />} />
        <Route path="*" element={<Navigate to="/admin/users/admins" />} />
      </Routes>
    </section>
  </div>
);

export default Admin;
