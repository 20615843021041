import { configureStore } from "@reduxjs/toolkit";
import reducerAuth from "slicers/auth";
import reducerUser from "slicers/user";

const store = configureStore({
  reducer: {
    auth: reducerAuth,
    user: reducerUser
  }
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
