import { firmwareFileNameRegExp } from "./constants";
import utilComparers from 'utils/comparers';

const validateEmail = (email: string) => {
  if (/\s/.test(email)) {
    return false;
  }
  return /^[^@]+@[^@]+$/.test(email);
}

const validateVersion = (currentMaxVersion: string, version: string) => {
  const compareResult = utilComparers.compareVersions(currentMaxVersion, version)
  if (compareResult > 0) return true;
  return false;
}

const validateFirmwareFileName = (filename: string) : Boolean => {
  const regExpResult = filename.match(firmwareFileNameRegExp);

  if (regExpResult) return true;
  return false;
}

const emailError = () => [{code: "email_format", message: "Must be \"*@*\" format without any whitespaces"}]
const versionError = () => [{code: "version_sequence", message: "Version number must be greater then the last released version"}]
const fileNameError = () => [{code: "filename_format", message: "File name format is wrong"}]

const util = {
  validateEmail,
  validateVersion,
  validateFirmwareFileName,
  emailError,
  versionError,
  fileNameError
};

export default util;
