import s from "./index.module.scss";

const PrimaryButton = ({
  children = "",
  className = "",
  disabled = false,
  onClick = () => {},
}) => (
  <button
    className={s["primary_button"] + (className ? ` ${className}` : "")}
    disabled={disabled}
    onClick={() => (disabled ? null : onClick())}
  >
    {children}
  </button>
);

export default PrimaryButton;
