import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Input from "components/inputs/Input";
import PasswordInput from "components/inputs/PasswordInput";
import PrimaryButton from "components/buttons/PrimaryButton";
import iconLogo from "assets/icons/logo.svg";
import serviceAuth from "services/auth";
import s from "./index.module.scss";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const isButtonDisabled = !email.length || !password.length || isError || isLoading;

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>, handlerKey: "email" | "password") => {
    const handlers = {
      "email": setEmail,
      "password": setPassword
    };
    const handler = handlers[handlerKey];
    handler(e.target.value);
    setIsError(false);
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isButtonDisabled) {
      return;
    }
    setIsLoading(true);
    serviceAuth
      .login(email.trim(), password)
      .then(() => navigate("/cabinet"))
      .catch(() => {
        setIsError(true);
        setIsLoading(false);
      });
  }

  return (
    <div className={s["login"]}>
      <div className={s["login__wrapper"]}>
        <img
          src={iconLogo}
          className={s["login__icon"]}
          alt="Logo"
        />
        <h1
          className={
            s["login__title"] +
            (isError ? ` ${s["login__title--error"]}` : "")
          }
        >
          Log in
        </h1>
        {
          isError
            ? <p className={s["login__error"]}>Wrong email or password, please try again.</p>
            : <></>
        }
        <form className={s["login__form"]} onSubmit={handleSubmit}>
          <Input
            type="email"
            className={s["login__input"]}
            value={email}
            placeholder="Email"
            autocomplete="email"
            isError={isError}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeInput(e, "email")}
          />
          <PasswordInput
            className={s["login__input"]}
            value={password}
            placeholder="Password"
            autocomplete="password"
            isError={isError}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeInput(e, "password")}
          />
          <PrimaryButton
            className={
              s["login__button"] +
              (isError ? ` ${s["login__button--error"]}` : "")
            }
            disabled={isButtonDisabled}
          >
            Log in
          </PrimaryButton>
        </form>
      </div>
    </div>
  );
}

export default Login;
