import serviceApi from "services/api";
import store from "store";
import { setFirstName, setId } from "slicers/user";

const baseURL = "users";

const get = () => serviceApi.get(baseURL + "/me")
  .then((user) => {
    store.dispatch(setFirstName(user.profile.first_name));
    store.dispatch(setId(user.id));
    return user;
  })
  .catch(() => {});

const service = {
  get
};

export default service;
