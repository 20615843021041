import { useState, useLayoutEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import RequiredAnonymous from "components/routes/RequiredAnonymous";
import RequiredAuth from "components/routes/RequiredAuth";
import Login from "./pages/login";
import Cabinet from "pages/cabinet";
import Admin from "pages/admin";
import Logout from "pages/logout";
import serviceAuth from "services/auth";
import serviceTokens from "services/tokens";
import s from "./App.module.scss";

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  useLayoutEffect(() => {
    const load = async () => {
      serviceAuth.loadTokens();
      if (serviceTokens.getRefresh()) {
        await serviceAuth.refresh()
      }
      setIsLoading(false);
    }
    load()
  }, [])

  if (isLoading) {
    return <></>
  }

  return (
    <div className={s["app"]}>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route element={<RequiredAnonymous />}>
          <Route path="/login" element={<Login />} />
        </Route>
        <Route element={<RequiredAuth />}>
          <Route path="/cabinet" element={<Cabinet />} />
          <Route path="/admin/*" element={<Admin />} />
          <Route path="/logout" element={<Logout />} />
        </Route>
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </div>
  );
}

export default App;
