import serviceApi from "services/api";
import Firmware from "types/firmware";
import UploadedFile from "types/uploadedFile";

const baseURL = "firmwares";

const getFirmwaresList = () : Promise<Array<Firmware>> => serviceApi.get(baseURL);

const buildFormData = (key: string, file: File) => {
  const formData = new FormData();
  formData.append(key, file);
  return formData;
};

const uploadFile = async (fileToUpload: File) => {
  return await serviceApi.post(
      baseURL + "/upload_firmware",
      buildFormData('file', fileToUpload)
  )
}

const createFirmware = async (title: string, patchNotes: string, state: string, attachedFile: File) => {
  const uploadedFile = await uploadFile(attachedFile)
  return await serviceApi.post(
    baseURL,
    {
      "title": title,
      "patch_notes": patchNotes,
      "state": state,
      "uploaded_file": uploadedFile
    }
  )
}

const editFirmware = async (id: string, title: string, patchNotes: string, state: string, attachedFile: UploadedFile | File) => {
  let uploadedFile : (UploadedFile | null) = null;

  if (attachedFile instanceof File) {
    uploadedFile = await uploadFile(attachedFile)
  }
  else {
    uploadedFile = attachedFile
  }

  return await serviceApi.put(
    baseURL + "/" + id,
    {
      "title": title, 
      "patch_notes": patchNotes,
      "state": state,
      "uploaded_file": uploadedFile as UploadedFile
    }
  )
};

const deleteFirmware = (id: string) => serviceApi.delete(baseURL + "/" + id)

const service = {
  getFirmwaresList,
  createFirmware,
  editFirmware,
  deleteFirmware,
  uploadFile
};

export default service;
