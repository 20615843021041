import { useState, useEffect, useCallback, useMemo } from "react";
import PrimaryButton from "components/buttons/PrimaryButton";
import Table from "components/Table";
import CreateFirmwareModal from "components/modals/CreateFirmwareModal";
import EditFirmwareModal from "components/modals/EditFirmwareModal";
import DeleteFirmwareModal from "components/modals/DeleteFirmwareModal";
import serviceFirmwares from "services/firmwares";
import utilFormatters from "utils/formatters";
import utilComparers from "utils/comparers";
import Firmware from "types/firmware";
import iconPen from "assets/icons/pen.svg";
import iconCross from "assets/icons/cross.svg";
import s from "./index.module.scss";

const Firmwares = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [firmwares, setFirmwares] = useState<Array<Firmware>>([]);
  const [isOpenModalCreateFirmware, setIsOpenModalCreateFirmware] = useState(false);
  const [isOpenModalEditFirmware, setIsOpenModalEditFirmware] = useState(false);
  const [isOpenModalDeleteFirmware, setIsOpenModalDeleteFirmware] = useState(false);
  const [firmwareToEdit, setFirmwareToEdit] = useState<null | Firmware>(null);
  const [firmwareToDelete, setFirmwareToDelete] = useState<null | Firmware>(null);

  const isDisabledButton = Boolean(isOpenModalCreateFirmware || isOpenModalEditFirmware || isOpenModalDeleteFirmware);
  const isAddNewFirmwareButtonDisabled = 
  firmwares.filter(firmware => firmware.state === "pending").length > 0 || isDisabledButton;

  const currentMaxFirmwareVersion = useMemo(() => {
    if (firmwares.length === 0) {
      return "0.00";
    }
    
    const releasedFirmwares = firmwares.filter(firmware => firmware.state === "release")
    if (releasedFirmwares.length === 0) return "0.00";

    const releasedFirmwaresVersions = releasedFirmwares.map(firmware => firmware.version)
    const sortedReleasedFirmwaresVersions = releasedFirmwaresVersions.sort(utilComparers.compareVersions)
    return sortedReleasedFirmwaresVersions[0]
  }, [firmwares])

  useEffect(() => {
    serviceFirmwares.getFirmwaresList()
      .then(setFirmwares)
      .finally(() => setIsLoading(false))
  }, [])

  const handleClickAddNewFirmwareButton = () => {
    if (isAddNewFirmwareButtonDisabled) {
      return;
    }
    setIsOpenModalCreateFirmware(true);
  }

  const handleCloseAddNewFirmwareModal = useCallback((firmware?: Firmware) => {
    setIsOpenModalCreateFirmware(false);
    if (firmware) {
      setFirmwares(firmwares => [firmware, ...firmwares]);
    }
  }, [])

  const handleClickEditFirmware = (firmware: Firmware) => {
    if (isDisabledButton) {
      return;
    }
    setFirmwareToEdit(firmware);
    setIsOpenModalEditFirmware(true);
  }

  const handleCloseEditFirmwareModal = useCallback((firmware?: Firmware) => {
    setIsOpenModalEditFirmware(false);
    if (firmware) {
      setFirmwares(firmwares => firmwares.map(currentFirmware => currentFirmware.id === firmware.id ? firmware : currentFirmware));
    }
  }, [])

  const handleClickDeleteFirmware = (firmware: Firmware) => {
    setFirmwareToDelete(firmware);
    setIsOpenModalDeleteFirmware(true);
  }

  const handleCloseDeleteFirmwareModal = useCallback((isDeleted: boolean) => {
    if (isDeleted) {
      setFirmwares(firmwares => firmwares.filter(firmware => firmware.id !== (firmwareToDelete as Firmware).id));
    }
    setIsOpenModalDeleteFirmware(false);
  }, [setFirmwares, firmwareToDelete])

  if (isLoading) {
    return <></>
  }

  return (
    <div>
      <div className={`${s["firmware__button-wrapper"]} ${isAddNewFirmwareButtonDisabled ? s["firmware__button-wrapper--error"] : ''}`}>
        <PrimaryButton 
          className={s["firmwares__button"]} 
          onClick={handleClickAddNewFirmwareButton}
          disabled={isAddNewFirmwareButtonDisabled}
        >
          Add Firmware Update
        </PrimaryButton>
      </div>
      <section className={s["firmwares__table-wrapper"]}>
        <Table
          titles={["Сreation date", "Title", "Version", "Actions", "State"]}
          headerColumnsSizes={["198fr", "316fr", "89fr", "minmax(60px, 60fr)", "159fr"]}
          rows={firmwares.map(firmware => {
            const isPendingForRelease = firmware.state === "pending";
            return [
              <p>{utilFormatters.formatDate(firmware.created_at)}</p>,
              <p>{firmware.title}</p>,
              <p>{firmware.version}</p>,
              isPendingForRelease
                ? (
                  <div className={s["firmwares__icons-wrapper"]}>
                    <img
                      className={s["firmwares__icon"]}
                      src={iconPen}
                      alt="Edit firmware"
                      onClick={() => handleClickEditFirmware(firmware)}
                    />
                    <img
                      className={s["firmwares__icon"]}
                      src={iconCross}
                      alt="Delete firmware"
                      onClick={() => handleClickDeleteFirmware(firmware)}
                    />
                  </div>
                )
                : <div></div>,
              <p
                className={
                  s["firmwares__text"]
                  + (isPendingForRelease ? ` ${s["firmwares__text--light"]}` : "")
                }
              >
                {utilFormatters.formatFirmwareState(firmware.state)}
              </p>
            ]
          })}
          rowsColumnsSizes={["196fr", "316fr", "89fr", "minmax(60px, 60fr)", "159fr"]}
        />
      </section>
      <CreateFirmwareModal
        isOpen={isOpenModalCreateFirmware}
        currentMaxFirmwareVersion={currentMaxFirmwareVersion}
        onClose={handleCloseAddNewFirmwareModal}
      />
      <EditFirmwareModal
        isOpen={isOpenModalEditFirmware}
        firmware={firmwareToEdit}
        currentMaxFirmwareVersion={currentMaxFirmwareVersion}
        onClose={handleCloseEditFirmwareModal}
       />
      <DeleteFirmwareModal
        isOpen={isOpenModalDeleteFirmware}
        onClose={handleCloseDeleteFirmwareModal}
        id={(firmwareToDelete as Firmware)?.id}
        title={(firmwareToDelete as Firmware)?.title}
      />
    </div>
  );
}

export default Firmwares;
