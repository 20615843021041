import { useState, memo } from "react";
import Modal from "components/Modal";
import Input from "components/inputs/Input";
import SecondaryButton from "components/buttons/SecondaryButton";
import PrimaryButton from "components/buttons/PrimaryButton";
import utilGenerators from "utils/generators";
import utilValidators from "utils/validators";
import utilErrorsPresenter, { ValidationError } from "utils/errors_presenter";
import serviceUsers from "services/users";
import s from "./index.module.scss";

const CreateAdminModal = ({
  isOpen = false,
  onClose = (admin?: any) => {}
}) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState<null | {[key: string]: Array<ValidationError>}>(null);
  const [isLoading, setIsLoading] = useState(false);

  const presentedErrors = utilErrorsPresenter.present(errors);

  const isDisabledCancelButton = isLoading;
  const isDisabledAddNewAdminButton = Boolean(isLoading || !firstName.trim().length || !lastName.trim().length || !email.trim().length || !password.length || errors);

  const reset = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPassword("");
    setErrors(null);
  }

  const handleClose = (admin?: any, discard: boolean = false) => {
    if (isDisabledCancelButton) {
      return;
    }
    if (discard) {
      reset();
    }
    onClose(admin);
  };

  const handleChangeInput = (value: string, handlerKey: "first_name" | "last_name" | "email" | "password") => {
    const handlers = {
      "first_name": setFirstName,
      "last_name": setLastName,
      "email": setEmail,
      "password": setPassword
    };
    const handler = handlers[handlerKey];
    handler(value);
    setErrors(null);
  }

  const handleClickGeneratePassword = () => {
    handleChangeInput(utilGenerators.generatePassword(), "password")
  }

  const handleClickAddNewAdmin = async () => {
    if (isDisabledAddNewAdminButton) {
      return;
    }

    const trimmedFirstName = firstName.trim();
    const trimmedLastName = lastName.trim();
    const trimmedEmail = email.trim();

    setIsLoading(true);
    const isValidEmail = utilValidators.validateEmail(trimmedEmail);
    if (!isValidEmail) {
      setErrors({email: utilValidators.emailError()});
      setIsLoading(false);
      return;
    }

    serviceUsers.createAdmin(trimmedFirstName, trimmedLastName, trimmedEmail, password)
      .then(admin => handleClose(admin, true))
      .catch(({ response: { data } }) => setErrors(data))
      .finally(() => setIsLoading(false));
  };

  return (
    <Modal isOpen={isOpen} onClose={() => handleClose(undefined, false)}>
      <div className={s["create-admin-modal"]}>
        <h1 className={s["create-admin-modal__title"]}>Add new admin</h1>
        <form className={s["create-admin-modal__form"]}>
          <label className={s["create-admin-modal__label"]}>First name</label>
          <Input
            value={firstName}
            onChange={e => handleChangeInput(e.target.value, "first_name")}
          />
          <label className={s["create-admin-modal__label"]}>Last name</label>
          <Input
            value={lastName}
            onChange={e => handleChangeInput(e.target.value, "last_name")}
          />
          <label className={s["create-admin-modal__label"]}>Email</label>
          <Input
            value={email}
            isError={Object.keys(presentedErrors).includes("email")}
            error={(presentedErrors["email"] || [])[0]}
            onChange={e => handleChangeInput(e.target.value, "email")}
          />
          <label className={s["create-admin-modal__label"]}>Password</label>
          <Input
            value={password}
            onChange={e => handleChangeInput(e.target.value, "password")}
          />
        </form>
        <p
          className={s["create-admin-modal__link"]}
          onClick={handleClickGeneratePassword}
        >
          Generate password
        </p>
        <div className={s["create-admin-modal__buttons-wrapper"]}>
          <SecondaryButton
            className={s["create-admin-modal__button"]}
            disabled={isDisabledCancelButton}
            onClick={() => handleClose(undefined, true)}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            className={s["create-admin-modal__button"]}
            disabled={isDisabledAddNewAdminButton}
            onClick={handleClickAddNewAdmin}
          >
            Add new admin
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};

export default memo(CreateAdminModal);
