import s from "./index.module.scss";

const Textarea = ({
  className = "",
  value = "",
  placeholder = "",
  autocomplete = "",
  onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {},
}) => (
  <textarea
    className={
      s["textarea"] +
      (className ? ` ${className}` : "")
    }
    value={value}
    placeholder={placeholder}
    autoComplete={autocomplete}
    onChange={onChange}
  />
);

export default Textarea;
