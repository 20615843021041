const getAccess = () => localStorage.getItem("access");

const getRefresh = () => localStorage.getItem("refresh");

const saveAccess = (accessToken: string) => localStorage.setItem("access", accessToken);

const saveRefresh = (refreshToken: string) => localStorage.setItem("refresh", refreshToken);

const removeAccess = () => localStorage.removeItem("access");

const removeRefresh = () => localStorage.removeItem("refresh");

const service = {
  getAccess,
  getRefresh,
  saveAccess,
  saveRefresh,
  removeAccess,
  removeRefresh
};

export default service;
