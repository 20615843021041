import React, { useState, useRef, useEffect, memo } from "react";
import Input from "components/inputs/Input";
import Textarea from "components/inputs/Textarea";
import Modal from "components/Modal";
import PrimaryButton from "components/buttons/PrimaryButton";
import SecondaryButton from "components/buttons/SecondaryButton";
import DarkButton from "components/buttons/DarkButton";
import AttachFileButton from "components/buttons/AttachFileButton";
import AttachedFile from "components/AttachedFile";
import UploadedFile from "types/uploadedFile";
import utilFormatters from "utils/formatters";
import utilValidators from "utils/validators";
import utilParsers from "utils/parsers";
import utilErrorsPresenter, { ValidationError } from "utils/errors_presenter";
import serviceFirmwares from "services/firmwares";
import s from "./index.module.scss";

const EditFirmwareModal = ({
  isOpen = false,
  firmware = null,
  currentMaxFirmwareVersion = "0.00",
  onClose = () => {}
} : {
  isOpen: boolean,
  firmware: any,
  currentMaxFirmwareVersion: string,
  onClose: (firmware?: any) => void
}) => {
  const [creationDate, setCreationDate] = useState("")
  const [title, setTitle] = useState("");
  const [version, setVersion] = useState("");
  const [patchNotes, setPatchNotes] = useState("");
  const [attachedFile, setAttachedFile] = useState<UploadedFile | File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<null | {[key: string]: Array<ValidationError>}>(null);

  const attachRef = useRef<HTMLInputElement | null>(null);

  const presentedErrors = utilErrorsPresenter.present(errors);

  const isDisabledCancelButton = isLoading;
  const isDisabledSaveDraftButton = Boolean(isLoading || !title.trim().length || !version.trim().length || !patchNotes.trim().length || !attachedFile || errors);
  const isDisabledReleaseUpdateButton = Boolean(isLoading || !title.trim().length || !version.trim().length || !patchNotes.trim().length || !attachedFile || errors);
  
  const versionError = presentedErrors["version"] ? 
  `${presentedErrors["version"][0]} (${currentMaxFirmwareVersion})`:
  "";
  const fileNameError = presentedErrors["fileName"] ? presentedErrors["fileName"][0] : "";
  const errorToShow = fileNameError || versionError;

  const attachedFileName = attachedFile ? (attachedFile instanceof File ? attachedFile.name : attachedFile.filename) : '';
  

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    setCreationDate(utilFormatters.formatDate(firmware.created_at));
    setTitle(firmware.title);
    setVersion(firmware.version.toString());
    setPatchNotes(firmware.patch_notes);
    setAttachedFile(firmware.uploaded_file);
    setErrors(null);
  }, [isOpen, firmware])

  const handleChangeInput = (value: string, handlerKey: "title" | "patchNotes") => {
    const handlers = {
      "title": setTitle,
      "patchNotes": setPatchNotes
    };
    const handler = handlers[handlerKey];
    handler(value);
    setErrors(null);
  }

  const handleClose = (firmware?: any) => {
    if (isDisabledCancelButton) {
      return;
    }
    onClose(firmware);
  };

  const handleClickAttach = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    attachRef.current?.click();
  }

  const handleChangeAttach = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isLoading) return;

    const files = (e.target as HTMLInputElement).files;
    if (!files) return;

    const file = files[0];
    setAttachedFile(file);

    const isFileNameValid = utilValidators.validateFirmwareFileName(file.name);
    if (!isFileNameValid) {
      setErrors({fileName: utilValidators.fileNameError()});
      return;
    }
    
    const firmwareVersion = utilParsers.parseVersionFromFileName(file.name);
    setVersion(firmwareVersion);
  };

  const handleClickDeleteFileButton = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAttachedFile(null);
    setVersion("");
    setErrors(null);
  }

  const editFirmware = (id: string, title: string, version: string, patchNotes: string, state: string, attachedFile: UploadedFile | File) => {
    setIsLoading(true);

    const isValidVersion = utilValidators.validateVersion(currentMaxFirmwareVersion, version);
    if (!isValidVersion) {
      setErrors({version: utilValidators.versionError()});
      setIsLoading(false);
      return;
    }
 
    serviceFirmwares.editFirmware(id, title, patchNotes, state, attachedFile)
    .then((firmware) => handleClose(firmware))
    .catch(({ response: { data } }) => setErrors(data))
    .finally(() => setIsLoading(false));
  }

  const handleClickSaveDraft = () => {
    if (isDisabledSaveDraftButton) return;

    const {formattedTitle, formattedVersion, formattedPatchNotes} = utilFormatters.formatFirmwareFormFieldsStates(title, version, patchNotes);
    const state = "pending";

    editFirmware(firmware.id, formattedTitle, formattedVersion, formattedPatchNotes, state, (attachedFile as UploadedFile | File));
  }

  const handleClickReleaseUpdate = () => {
    if (isDisabledReleaseUpdateButton) return;

    const {formattedTitle, formattedVersion, formattedPatchNotes} = utilFormatters.formatFirmwareFormFieldsStates(title, version, patchNotes);
    const state = "release"

    editFirmware(firmware.id, formattedTitle, formattedVersion, formattedPatchNotes, state, (attachedFile as UploadedFile | File));
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <div className={s["edit-firmware-modal"]}>
        <div className={s["edit-firmware-modal__title-wrapper"]}>
          <h1 className={`${s["edit-firmware-modal__title"]}`}>
            Version:
            <span className={`${s["edit-firmware-modal__title"]} ${s["edit-firmware-modal__title--value"]} ${versionError ? s["edit-firmware-modal__title--error"]: ''}`}>{version}</span>
          </h1>
          <h1 className={s["edit-firmware-modal__title"]}>
            Creation date:
            <span className={`${s["edit-firmware-modal__title"]} ${s["edit-firmware-modal__title--value"]}`}>{utilFormatters.formatDate(creationDate)}</span>
          </h1>
        </div>
        <form className={s["edit-firmware-modal__form"]}>
          <div className={s['edit-firmware-modal__field-wrapper']}>
            <label className={s["edit-firmware-modal__label"]}>Title</label>
            <Input
              value={title}
              className={s["edit-firmware-modal__input"]}
              onChange={e => handleChangeInput(e.target.value, "title")}
            />
          </div>
          <div className={s['edit-firmware-modal__field-wrapper']}>
            <label className={s["edit-firmware-modal__label"]}>Patch notes</label>
            <Textarea
              value={patchNotes}
              className={s["edit-firmware-modal__textarea"]}
              onChange={e => handleChangeInput(e.target.value, "patchNotes")}
            />
          </div>
          <div className={`${s['edit-firmware-modal__field-wrapper']} ${s['edit-firmware-modal__field-wrapper--attach-file']}`}>
          {
              attachedFile ?
                <AttachedFile
                  filename={attachedFileName}
                  onDelete={handleClickDeleteFileButton}
                 />
                :
                <>
                  <AttachFileButton onClick={handleClickAttach}/>
                  <input ref={attachRef} style={{ display: 'none' }} type="file" onChange={handleChangeAttach} />
                </>
            }
          </div>
        </form>
        <div className={s["edit-firmware-modal__errors-wrapper"]}>
            {errorToShow ? <p className={s["edit-firmware-modal__error"]}>{errorToShow}</p> : <></>}
        </div>
        <div className={s["edit-firmware-modal__buttons-wrapper"]}>
          <SecondaryButton
            className={s["edit-firmware-modal__button"]}
            disabled={isDisabledCancelButton}
            onClick={handleClose}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            className={s["edit-firmware-modal__button"]}
            disabled={isDisabledSaveDraftButton}
            onClick={handleClickSaveDraft}
          >
            Save draft
          </PrimaryButton>
          <DarkButton
            className={`${s["edit-firmware-modal__button"]}`}
            disabled={isDisabledReleaseUpdateButton}
            onClick={handleClickReleaseUpdate}
          >
            Release Update
          </DarkButton>
        </div>
      </div>
    </Modal>
  )
}

export default memo(EditFirmwareModal);
