import { useState } from "react";
import Input from "components/inputs/Input";
import iconEyeOpened from "assets/icons/eye-opened.svg";
import iconEyeClosed from "assets/icons/eye-closed.svg";
import s from "./index.module.scss";

const PasswordInput = ({
  className = "",
  ...props
}) => {
  const [isHidden, setIsHidden] = useState(true);

  const inputType = isHidden ? "password" : "text";
  const icon = isHidden ? iconEyeOpened : iconEyeClosed;

  const handleClickIcon = () => setIsHidden(!isHidden)

  return (
    <div className={s["password-input"]}>
      <Input
        type={inputType}
        className={
          s["password-input__input"] +
          (className ? ` ${className}` : "")
        }
        {...props}
      />
      <img
        className={s["password-input__icon"]}
        src={icon}
        alt="Toggle password visibility"
        onClick={handleClickIcon}
      />
    </div>
  );
}

export default PasswordInput;
