import axios from "axios";
import serviceAuth from "services/auth";
import store from "store";

const makeRequest = ({ headers = {}, ...config }: any): Promise<any> =>
  axios({
    ...config,
    url: process.env.REACT_APP_API_URL + config.url,
    headers: {
      ...headers,
      Authorization: `Bearer ${store.getState().auth.access}`,
    },
  })
    .then(({ data }) => data)
    .catch(async (error) => {
      if (
        error.response?.status !== 401 ||
        error.response?.config.url.endsWith("auth/refresh") ||
        error.response?.config.url.endsWith("auth/login") ||
        error.config.__isRetryRequest
      ) {
        return Promise.reject(error);
      }

      try {
        await serviceAuth.refresh();
      } catch (error) {
        serviceAuth.removeTokens();
        return Promise.reject(error);
      }

      error.config.__isRetryRequest = true;
      error.config.url = error.config.url.replace(
        process.env.REACT_APP_API_URL,
        ""
      );
      return makeRequest(error.config);
    });

const get = async (
  url: string,
  params: object = {}
) => makeRequest({ method: "GET", url, params });

const post = async (
  url: string,
  data: object = {},
  params: object = {}
) => makeRequest({ method: "POST", url, data, params });

const put = async (
  url: string,
  data: object
) => makeRequest({ method: "PUT", url, data });

const _delete = async (
  url: string,
  data: object = {}
) => makeRequest({ method: "DELETE", url, data });

const service = {
  get,
  post,
  put,
  delete: _delete
};

export default service;
