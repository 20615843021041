import React from "react";
import s from "./index.module.scss";

const Table = ({
  titles = [],
  headerColumnsSizes = [],
  rows = [],
  rowsColumnsSizes = []
}: {
  titles: string[],
  headerColumnsSizes: string[],
  rows: any[],
  rowsColumnsSizes: string[]
}) => (
  <div className={s["table"]}>
    <div
      className={s["table__row"] + " " + s["table__row--header"]}
      style={{ gridTemplateColumns: headerColumnsSizes.join(" ") }}
    >
      {
        titles.map((title, index) => (
          <p className={s["table__header-text"]} key={index}>{title}</p>
        ))
      }
    </div>

    <div className={s["table__rows-wrapper"]}>
      {
        rows.map((row, rowIndex) => (
          <div
            className={s["table__row"] + " " + s["table__row--row"]}
            style={{ gridTemplateColumns: rowsColumnsSizes.join(" ") }}
            key={rowIndex}
          >
            {row.map((item: React.ReactNode, itemIndex: number) => <React.Fragment key={itemIndex}>{item}</React.Fragment>)}
          </div>
        ))
      }
    </div>
  </div>
);

export default Table;
