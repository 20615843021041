import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "store";
import serviceAuth from "services/auth";

const Cabinet = () => {
  const refreshToken = useSelector((state: RootState) => state.auth.refresh);

  return <Navigate to={`/${serviceAuth.getUserRole(refreshToken)}`} />;
};

export default Cabinet;
