import { firmwareFileNameRegExp } from "utils/constants";

const parseVersionFromFileName = (fileName: string): string => {
  const result = fileName.match(firmwareFileNameRegExp);

  if (result) {
    const [, major, minor, beta] = result;
    return `${major}.${minor}${beta}`;
  }

  return "";
};

const util = {
  parseVersionFromFileName,
};

export default util;
