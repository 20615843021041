import React from "react";
import uploadIcon from "assets/icons/upload-file.svg";
import s from "./index.module.scss";

const AttachFileButton = ({
  className = "",
  disabled = false,
  onClick = (e: React.MouseEvent<HTMLElement>) => {},
}) => (
  <button
    className={s["attach-file-button"] + (className ? ` ${className}` : "")}
    disabled={disabled}
    onClick={(e) => (disabled ? null : onClick(e))}
  >
    <span className={s["attach-file-button__text"]}>Upload the file</span>
    <img className={s["attach-file-button__image"]} src={uploadIcon} alt="upload files" /> 
  </button>
);

export default AttachFileButton;
