export type ValidationError = {
  code: string,
  message: string
};

type Messages = {[key: string]: string};

const emailMessages: Messages = {
  email_format: "Incorrect email",
  unique: "Admin with specified email already registered"
};

const versionMessages: Messages = {
  version_sequence: "Version number must be greater then the last released version",
  latest_version: "Version number must be greater then the last released version",
  invalid_version: "Version number must be greater then the last released version"
};

const fileNameMessages: Messages = {
  filename_format: "File name format is wrong",
  firmware_filename: "File name format is wrong"
};

const presentErrors = (messages: Messages, errors: Array<ValidationError>): Array<string> => {
  const presentErrorMessage = (error: ValidationError): string => {
    if (!Object.keys(messages).includes(error.code)) {
      return "";
    }
    return messages[error.code];
  }

  return errors.reduce(
    (acc: Array<string>, error: ValidationError): Array<string> => ([...acc, presentErrorMessage(error)]),
    []
  ).filter((error: string) => Boolean(error.length));
}

const present = (errors: {[key: string]: Array<ValidationError>} | null) => {
  if (!errors) {
    return {}
  }

  const presenters: {[key: string]: any} = {
    email: (emailErrors: Array<ValidationError>) => presentErrors(emailMessages, emailErrors),
    version: (versionErrors: Array<ValidationError>) => presentErrors(versionMessages, versionErrors),
    fileName: (fileNameErrors: Array<ValidationError>) => presentErrors(fileNameMessages, fileNameErrors),
  };
  const result: {[key: string]: Array<string>} = {};
  Object.keys(errors).forEach(errorFieldKey => {
    if (!Object.keys(presenters).includes(errorFieldKey)) {
      return;
    }
    result[errorFieldKey] = presenters[errorFieldKey](errors[errorFieldKey])
  });
  return result;
}

const util = {
  present
};

export default util;
