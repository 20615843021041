import { useState, memo } from "react";
import Modal from "components/Modal";
import SecondaryButton from "components/buttons/SecondaryButton";
import PrimaryButton from "components/buttons/PrimaryButton";
import serviceFirmwares from "services/firmwares";
import s from "./index.module.scss";

const DeleteFirmwareModal = ({
  isOpen = false,
  onClose = (isDeleted: boolean) => {},
  id = "",
  title = ""
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const isDisabledCancelButton = isLoading;
  const isDisabledDeleteButton = isLoading;

  const handleClose = (isDeleted: boolean = false) => {
    if (isDisabledCancelButton) {
      return;
    }
    onClose(isDeleted);
  };

  const handleClickDeleteFirmware = async () => {
    if (isDisabledDeleteButton) {
      return;
    }

    setIsLoading(true);

    serviceFirmwares.deleteFirmware(id)
      .then(() => handleClose(true))
      .finally(() => setIsLoading(false));
  };

  return (
    <Modal isOpen={isOpen} onClose={() => handleClose(false)}>
      <div className={s["delete-firmware-modal"]}>
        <h1 className={s["delete-firmware-modal__title"]}>Remove Firmware Update</h1>
        <p className={s["delete-firmware-modal__text"]}>
          Are you sure you want<br />to remove {title}?
        </p>
        <div className={s["delete-firmware-modal__buttons-wrapper"]}>
          <SecondaryButton
            className={s["delete-firmware-modal__button"]}
            disabled={isDisabledCancelButton}
            onClick={() => handleClose(false)}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            className={s["delete-firmware-modal__button"]}
            disabled={isDisabledDeleteButton}
            onClick={handleClickDeleteFirmware}
          >
            Remove
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};

export default memo(DeleteFirmwareModal);
