import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    firstName: "",
    id: ""
  },
  reducers: {
    setFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    setId: (state, action) => {
      state.id = action.payload;
    },
    clearState: (state) => {
      state.firstName = "";
      state.id = "";
    }
  }
});

export const { setFirstName, setId, clearState } = userSlice.actions;

export default userSlice.reducer;
