import { useState, useRef, createRef, useEffect, useLayoutEffect } from "react";
import { createPortal } from "react-dom";
import s from "./index.module.scss";

const Modal = ({ isOpen = false, onClose = () => {}, children = <></> }) => {
  const [isClosing, setIsClosing] = useState(false);

  const isFirstRender = useRef(true);
  const modalWrapperRef = createRef();

  const animationDuration = parseInt(s["animation-duration"]);

  useEffect(() => {
    setTimeout(() => {
      if (!isFirstRender.current) {
        return;
      }
      isFirstRender.current = false;
    }, animationDuration)
  }, [animationDuration])

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
      return;
    }
    document.body.style.overflow = "auto";
  }, [isOpen]);

  useLayoutEffect(() => {
    if (isFirstRender.current) {
      return;
    }
    if (isOpen) {
      return;
    }
    setIsClosing(true);
    setTimeout(() => setIsClosing(false), animationDuration);
  }, [isOpen, animationDuration])

  const handleClickModalComponent = (e: React.MouseEvent<HTMLElement>) => {
    if ((modalWrapperRef.current as HTMLElement).contains(e.target as any) && modalWrapperRef.current !== e.target) {
      return;
    }
    onClose();
  };

  if (!isOpen && !isClosing) {
    return <></>
  }

  return createPortal(
    <div
      className={
        s["modal-component"]
        + (isClosing ? ` ${s["modal-component--closing"]}` : "")
      }
      onClick={handleClickModalComponent}
    >
      <div
        ref={modalWrapperRef as React.RefObject<HTMLDivElement>}
        className={
          s["modal-component__modal-wrapper"]
          + (isClosing ? ` ${s["modal-component__modal-wrapper--closing"]}` : "")
        }
      >
        {children}
      </div>
    </div>,
    document.querySelector("#modal-root") as HTMLElement
  );
};

export default Modal;
