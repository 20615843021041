import { useEffect } from "react";
import serviceAuth from "services/auth";

const Logout = () => {
  useEffect(() => {
    serviceAuth.logout()
  }, []);

  return <></>;
};

export default Logout;
