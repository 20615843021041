import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import PrimaryButton from "components/buttons/PrimaryButton";
import Table from "components/Table";
import CreateAdminModal from "components/modals/CreateAdminModal";
import EditAdminModal from "components/modals/EditAdminModal";
import DeleteAdminModal from "components/modals/DeleteAdminModal";
import serviceUsers from "services/users";
import serviceUser from "services/user";
import Admin from "types/admin";
import iconPen from "assets/icons/pen.svg";
import iconCross from "assets/icons/cross.svg";
import s from "./index.module.scss";

const Admins = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [admins, setAdmins] = useState<Array<Admin>>([]);
  const [isOpenModalAddNewAdmin, setIsOpenModalAddNewAdmin] = useState(false);
  const [isOpenModalEditAdmin, setIsOpenModalEditAdmin] = useState(false);
  const [adminToEdit, setAdminToEdit] = useState<null | Admin>(null);
  const [isOpenModalDeleteAdmin, setIsOpenModalDeleteAdmin] = useState(false);
  const [adminToDelete, setAdminToDelete] = useState<null | Admin>(null);

  const myId = useSelector((state: RootState) => state.user.id);

  const isDisabledButton = Boolean(isOpenModalAddNewAdmin || isOpenModalEditAdmin || isOpenModalDeleteAdmin);

  useEffect(() => {
    serviceUsers.getAdminsList()
      .then(setAdmins)
      .finally(() => setIsLoading(false))
  }, [])

  const handleClickAddNewAdminButton = () => {
    if (isDisabledButton) {
      return;
    }
    setIsOpenModalAddNewAdmin(true);
  }

  const handleCloseAddNewAdminModal = useCallback((admin?: Admin) => {
    setIsOpenModalAddNewAdmin(false);
    if (admin) {
      setAdmins(admins => [...admins, admin]);
    }
  }, [])

  const handleClickEditAdmin = (admin: Admin) => {
    if (isDisabledButton) {
      return;
    }
    setAdminToEdit(admin);
    setIsOpenModalEditAdmin(true);
  }

  const handleCloseEditAdminModal = useCallback((admin?: Admin) => {
    setIsOpenModalEditAdmin(false);
    if (!admin) {
      return;
    }
    setAdmins(admins => admins.map(currentAdmin => currentAdmin.id === admin.id ? admin : currentAdmin));
    if (admin.id === myId) {
      serviceUser.get();
    }
  }, [setAdmins, myId])

  const handleClickDeleteAdmin = (admin: Admin) => {
    if (isDisabledButton) {
      return;
    }
    setAdminToDelete(admin);
    setIsOpenModalDeleteAdmin(true);
  }

  const handleCloseDeleteAdminModal = useCallback((isDeleted: boolean) => {
    if (isDeleted) {
      setAdmins(admins => admins.filter(admin => admin.id !== (adminToDelete as Admin).id));
    }
    setIsOpenModalDeleteAdmin(false);
  }, [setAdmins, adminToDelete])

  if (isLoading) {
    return <></>
  }

  return (
    <div>
      <PrimaryButton
        disabled={isDisabledButton}
        onClick={handleClickAddNewAdminButton}
      >
        Add New Admin
      </PrimaryButton>
      <section className={s["admins__table-wrapper"]}>
        <Table
          titles={["Name", "Email", "Actions"]}
          headerColumnsSizes={["392fr", "445fr", "minmax(85px, 85fr)"]}
          rows={admins.map(admin => ([
            <p>{admin.profile.first_name} {admin.profile.last_name}</p>,
            <p>{admin.email}</p>,
            <div className={s["admins__icons-wrapper"]}>
              <img
                className={s["admins__icon"]}
                src={iconPen}
                alt="Edit admin"
                onClick={() => handleClickEditAdmin(admin)}
              />
              {
                admin.id === myId
                  ? <></>
                  : (
                    <img
                      className={s["admins__icon"]}
                      src={iconCross}
                      alt="Delete admin"
                      onClick={() => handleClickDeleteAdmin(admin)}
                    />
                  )
              }
            </div>
          ]))}
          rowsColumnsSizes={["390fr", "445fr", "minmax(85px, 85fr)"]}
        />
      </section>
      <CreateAdminModal
        isOpen={isOpenModalAddNewAdmin}
        onClose={handleCloseAddNewAdminModal}
      />
      <EditAdminModal
        isOpen={isOpenModalEditAdmin}
        onClose={handleCloseEditAdminModal}
        admin={adminToEdit as Admin}
      />
      <DeleteAdminModal
        isOpen={isOpenModalDeleteAdmin}
        onClose={handleCloseDeleteAdminModal}
        id={(adminToDelete as Admin)?.id}
        name={(adminToDelete as Admin)?.profile.first_name + " " + (adminToDelete as Admin)?.profile.last_name}
      />
    </div>
  );
}

export default Admins;
