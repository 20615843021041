import serviceApi from "services/api";
import Admin from "types/admin";

const baseURL = "users";

const getAdminsList = () : Promise<Array<Admin>> => serviceApi.get(baseURL + "/admins");

const deleteAdmin = (id: string) => serviceApi.delete(baseURL + "/admins/" + id)

const createAdmin = (first_name: string, last_name: string, email: string, password: string) => serviceApi.post(
  baseURL + "/admins",
  {first_name, last_name, email, password}
)

const editAdmin = (id: string, first_name: string, last_name: string, email: string, password?: string) => {
  const data: {
    first_name: string,
    last_name: string,
    email: string,
    password?: string
  } = {
    first_name,
    last_name,
    email
  }
  if (password && password.length) {
    data.password = password
  }

  return serviceApi.put(baseURL + "/admins/" + id, data)
}

const service = {
  getAdminsList,
  deleteAdmin,
  createAdmin,
  editAdmin
};

export default service;
