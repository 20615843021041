import CrossIcon from "assets/icons/cross.svg";
import s from "./index.module.scss";

const AttachedFile = ({
  filename = "",
  onDelete = (event: React.MouseEvent<HTMLElement>) => {},
}) => {
  return (
    <div className={s["attached-file"]}>
      <div className={s["attached-file__file"]}>
        <span className={s["attached-file__title"]} title={filename}>
          {filename}
        </span>
        <button
          className={s["attached-file__delete-button"]}
          title="Delete file"
          onClick={onDelete}
        >
          <img
            className={s["attached-file__delete-button-img"]}
            src={CrossIcon}
            alt="Cross icon"
          />
        </button>
      </div>
    </div>
  );
};

export default AttachedFile;
