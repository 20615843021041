import s from "./index.module.scss";

const Input = ({
  type = "text",
  className = "",
  value = "",
  placeholder = "",
  autocomplete = "",
  isError = false,
  error = "",
  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {},
}) => (
  <div>
    <input
      type={type}
      className={
        s["input__input"] +
        (isError ? ` ${s["input__input--error"]}` : "") +
        (className ? ` ${className}` : "")
      }
      value={value}
      placeholder={placeholder}
      autoComplete={autocomplete}
      onChange={onChange}
    />
    {
      error
        ? <p className={s["input__error"]}>{error}</p>
        : <></>
    }
  </div>
);

export default Input;
