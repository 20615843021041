import { versionRegExp } from "./constants";

const versionStringToVersionNumber = (version: string): number => {
  if (version.endsWith('b')) {
    version = version.replaceAll('b', '0')
  } else {
    version += '1'
  }
  version = version.replaceAll('.', '')
  return parseInt(version)
};

const util = {
  versionStringToVersionNumber,
};

export default util;
